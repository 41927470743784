import NextErrorComponent from 'next/error';
import FallbackUI from '@ui/molecules/ErrorBoundary/FallbackUI';
import {useEffect} from "react";
import {logEvent, moengageEventArgsConstants, moengageEventConstants} from "@services/moengage";
import {useRouter} from "next/router";

const MyError = ({ statusCode }) => {
    const router = useRouter();

    useEffect(() => {
        logEvent({
            eventName: moengageEventConstants.VIEWED_ERROR_PAGE,
            parameters: {
                [moengageEventArgsConstants.STATUS_CODE]: statusCode,
                [moengageEventArgsConstants.PATH]: router?.pathname
            }
        });
    }, []);

    return <FallbackUI statusCode={statusCode} />;
};

MyError.getInitialProps = async (context) => {
    const errorInitialProps = await NextErrorComponent.getInitialProps(context);

    const { res, err } = context;

    errorInitialProps.hasGetInitialPropsRun = true;

    return {
        errorData: err,
        statusCode: res?.statusCode,
        ...errorInitialProps,
    };
};

export default MyError;
